import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { IProduct, ISodiumWarningFields } from '../../../@generated/@types/contentful';
import { useBag, useDomainMenu, usePdp } from '../../../redux/hooks';
import {
    useProductIsCombo,
    useProductCategory,
    useDiscountPriceAndCalories,
    useProductItemGroup,
    useDefaultTallyItem,
    useTallyPriceAndCalories,
    useDomainProductWithRootIdByContentfulFields,
    useDomainProductByContentfulFields,
    useIsProductEditable,
} from '../../../redux/hooks/domainMenu';

import { useProductOrderAheadAvailability } from '../../../common/hooks/useProductOrderAheadAvailability';

import { useAutoDiscounts } from '../../../common/hooks/useAutoDiscounts';
import { useProductIsSaleable } from '../../../common/hooks/useProductIsSaleable';
import { useProductIsAvailable } from '../../../common/hooks/useProductIsAvailable';
import { useProductHasRequiredModifiers } from '../../../common/hooks/useProductHasRequiredModifiers';
import { InspireCmsEntry } from '../../../common/types';
import { BOGOS_LABELS_MAP, IS_USE_DOMAIN_PRODUCT_WITH_ROOT_ID } from './constants';
import { useTallyItemHasSodiumWarning } from '../../../common/hooks/useTallyItemHasSodiumWarning';
import { GTM_MODIFY_PRODUCT } from '../../../common/services/gtmService/constants';

import VerticalProductCard from '../../atoms/verticalProductCard/verticalProductCard';
import { useProductHasOtherSizes } from '../../../common/hooks/useProductHasOtherSizes';

interface IVerticalProductCardContainerProps {
    item: IProduct;
    recommendationId?: string;
    isSideScroll?: boolean;
    category: string;
    className?: string;
    gtmId?: string;
    onModify?: () => void;
    onCardLinkClick?: () => void;
    containerClass?: string;
    addClass?: string;
    modifyClass?: string;
    crossSellGtmId?: string;
    sodiumWarning?: InspireCmsEntry<ISodiumWarningFields>;
    categoryId?: string;
    shouldShowCaloriesAndPrice?: boolean;
    shouldShowControls?: boolean;
    shouldPreloadImages?: boolean;
    showDiscountBadge?: boolean;
}

const VerticalProductCardContainer: FC<IVerticalProductCardContainerProps> = (props): JSX.Element => {
    const {
        item,
        recommendationId,
        isSideScroll = false,
        category,
        className,
        gtmId = 'productItem',
        onModify,
        onCardLinkClick,
        containerClass = '',
        addClass = '',
        modifyClass = '',
        crossSellGtmId,
        sodiumWarning,
        categoryId,
        shouldShowCaloriesAndPrice = true,
        shouldShowControls = true,
        shouldPreloadImages = false,
        showDiscountBadge = false,
    } = props;

    const { fields } = item;
    const { name, showProductBadge } = fields;

    const getProduct = IS_USE_DOMAIN_PRODUCT_WITH_ROOT_ID
        ? useDomainProductWithRootIdByContentfulFields
        : useDomainProductByContentfulFields;
    const product = getProduct(fields);

    const productId = product?.id;

    const productItemGroup = useProductItemGroup(productId);

    const bogoBadge = BOGOS_LABELS_MAP[productItemGroup?.name]?.();

    const defaultTallyItem = useDefaultTallyItem(productId);

    const productHasSodiumWarning = useTallyItemHasSodiumWarning(defaultTallyItem);
    const showSodiumWarning = !!sodiumWarning && productHasSodiumWarning;

    const productBadgeCategory = useProductCategory(productId);
    const productBadge = showProductBadge ? productBadgeCategory?.name : null;

    const productName = product && 'menuItemName' in product ? product['menuItemName'] : product?.name || name || '';

    const { price } = useDiscountPriceAndCalories(productId);
    const { discountedProducts } = useAutoDiscounts([productId]);

    const discountedPrice = discountedProducts[0]?.discountedPrice;

    const { calories, calorieRange } = useTallyPriceAndCalories(defaultTallyItem, false);

    const bag = useBag();

    const handleAddToBagClick = () => {
        bag.actions.addDefaultToBag({ productId, name: productName, category, recommendationId });
    };

    const dispatch = useDispatch();
    const pdp = usePdp();

    const handleModifyClick = () => {
        dispatch({ type: GTM_MODIFY_PRODUCT });
        pdp.actions.resetPdpState();
        bag.actions.setIsUpdateBagItem(false);

        if (onModify) {
            onModify();
        }
    };

    const handleCardLinkClick = () => {
        bag.actions.setIsUpdateBagItem(false);

        if (onCardLinkClick) {
            onCardLinkClick();
        }
    };

    const { loading } = useDomainMenu();

    const isCombo = useProductIsCombo(productId);
    const { isSaleable } = useProductIsSaleable(productId);
    const { isAvailable } = useProductIsAvailable(productId, categoryId);
    const { isOrderAheadAvailable } = useProductOrderAheadAvailability(productId);

    const categoryUrl = category === 'recommended-items' ? '' : category;

    const productHasRequiredModifier = useProductHasRequiredModifiers(product?.id);
    const isEditable = useIsProductEditable(productId);
    const productHasOtherSizes = useProductHasOtherSizes(productId);

    const canProductBeModified = isEditable || productHasOtherSizes;

    return (
        <VerticalProductCard
            item={item}
            product={product}
            productName={productName}
            isSideScroll={isSideScroll}
            categoryUrl={categoryUrl}
            className={className}
            gtmId={gtmId}
            crossSellGtmId={crossSellGtmId}
            containerClass={containerClass}
            addClass={addClass}
            modifyClass={modifyClass}
            sodiumWarning={sodiumWarning}
            shouldShowSodiumWarning={showSodiumWarning}
            price={price}
            discountedPrice={discountedPrice}
            calories={calories}
            calorieRange={calorieRange}
            isLoading={loading}
            isCombo={isCombo}
            isSaleable={isSaleable}
            isAvailable={isAvailable}
            isOrderAheadAvailable={isOrderAheadAvailable}
            isProductHasRequiredModifiers={productHasRequiredModifier}
            canProductBeModified={canProductBeModified}
            productBadge={productBadge}
            bogoBadge={bogoBadge}
            onAddToBag={handleAddToBagClick}
            onModify={handleModifyClick}
            onCardLinkClick={handleCardLinkClick}
            shouldShowCaloriesAndPrice={shouldShowCaloriesAndPrice}
            shouldShowControls={shouldShowControls}
            shouldImageLoadEager={shouldPreloadImages}
            showDiscountBadge={showDiscountBadge}
        />
    );
};

export default VerticalProductCardContainer;
