import { IProducts } from '../../redux/hooks/domainMenu';

export const getProductByIds = (ids: string[], products: IProducts) => {
    if (products) {
        for (const id of ids) {
            if (products[id]) {
                return products[id];
            }
        }
    }

    return null;
};
