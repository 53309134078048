import React, { FC } from 'react';
import classnames from 'classnames';

import { IProduct } from '../../../../@generated/@types/contentful';
import { Divider } from '../../divider';
import Loader from '../../Loader';
import { InspireLink, InspireLinkButton } from '../../link';
import { LABELS } from './constants';
import { shouldShowViewItem, shouldReverseButtonsOrder } from './helpers';
import styles from './productItemControls.module.css';

export interface IProductItemControlsProps {
    isOrderAheadAvailable: boolean;
    product: IProduct;
    isSaleable: boolean;
    isAvailable: boolean;
    isCombo?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    actionsWrapperGtmId?: string;
    gtmId?: string;
    onModify?: () => void;
    addClass?: string;
    modifyClass?: string;
    isLoading?: boolean;
    canProductBeModified?: boolean;
    isProductHasRequiredModifier?: boolean;
    categoryUrl?: string;
}

const ProductItemControls: FC<IProductItemControlsProps> = (props) => {
    const {
        onClick,
        isOrderAheadAvailable,
        categoryUrl,
        product,
        isSaleable,
        isAvailable,
        isCombo,
        gtmId = 'productItem',
        actionsWrapperGtmId,
        onModify,
        addClass,
        modifyClass,
        isLoading = false,
        isProductHasRequiredModifier = false,
        canProductBeModified = false,
    } = props;

    const handleModifyLinkClick = () => {
        if (onModify) {
            onModify();
        }
    };

    if (isLoading) {
        return (
            <div data-gtm-id={actionsWrapperGtmId} className={styles.wrapper}>
                <span>
                    <Loader size={20} />
                </span>
            </div>
        );
    }

    if (!isSaleable || !isAvailable || !isOrderAheadAvailable) {
        if (!shouldShowViewItem()) {
            return null;
        }

        return (
            <div data-gtm-id={actionsWrapperGtmId} className={styles.wrapper}>
                <InspireLink
                    link={product}
                    currentCategoryUrl={categoryUrl}
                    className={classnames(styles.productItemButton)}
                    gtmId={gtmId}
                    type="secondary"
                >
                    {LABELS.VIEW_ITEM}
                </InspireLink>
            </div>
        );
    }

    const addToBagJsx = (
        <InspireLinkButton
            linkType="secondary"
            gtmId={gtmId}
            onClick={onClick}
            className={classnames(addClass, styles.productItemButton)}
            key={LABELS.ADD_TO_BAG}
        >
            <span data-gtm-id={gtmId}>{LABELS.ADD_TO_BAG}</span>
        </InspireLinkButton>
    );

    const modifyBtnJsx = (
        <InspireLink
            type="secondary"
            link={product}
            currentCategoryUrl={categoryUrl}
            className={classnames(modifyClass, styles.productItemButton)}
            onClick={handleModifyLinkClick}
            gtmId={gtmId}
            key={LABELS.MODIFY}
        >
            {LABELS.MODIFY}
        </InspireLink>
    );

    if (!canProductBeModified && !isCombo) {
        return (
            <div data-gtm-id={actionsWrapperGtmId} className={styles.wrapper}>
                {addToBagJsx}
            </div>
        );
    }

    if (isProductHasRequiredModifier) {
        return (
            <div data-gtm-id={actionsWrapperGtmId} className={styles.wrapper}>
                {modifyBtnJsx}
            </div>
        );
    }

    const dividerJsx = <Divider key="divider" />;

    const addToBagAndModifyButtons = [addToBagJsx, dividerJsx, modifyBtnJsx];

    if (shouldReverseButtonsOrder()) {
        addToBagAndModifyButtons.reverse();

        return (
            <div data-gtm-id={actionsWrapperGtmId} className={styles.wrapper}>
                {addToBagAndModifyButtons}
            </div>
        );
    }

    return (
        <div data-gtm-id={actionsWrapperGtmId} className={styles.wrapper}>
            {addToBagAndModifyButtons}
        </div>
    );
};

export default ProductItemControls;
