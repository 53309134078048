import { AutoDiscountOfferModel } from '../../@generated/webExpApi';

export interface IDiscountedProduct {
    productId: string;
    discount?: AutoDiscountOfferModel;
    discountedPrice?: number;
    discountAmount: number;
}
interface UseAutoDiscountsHook {
    discountedProducts: IDiscountedProduct[];
}

export const useAutoDiscounts = (productIds: string[]): UseAutoDiscountsHook => {
    const discountedProducts = productIds.map((productId) => {
        return {
            productId,
            discountAmount: 0,
        };
    });

    return { discountedProducts };
};
