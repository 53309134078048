import React, { FC } from 'react';
import classnames from 'classnames';

import { IProduct, ISodiumWarningFields } from '../../../@generated/@types/contentful';

import { VerticalProductCardContent } from './verticalProductCardContent';
import ProductItemControls from './productItemControls';
import { InspireLink } from '../link';

import styles from './verticalProductCard.module.css';
import InspireBadge from '../Badge';
import { InspireCmsEntry } from '../../../common/types';
import { IDomainProductItem } from '../../../redux/types';
import ContentfulBadge from '../../molecules/contentfulBadge';
import { ItemType } from '../../../common/constants/badge';
import { useBadge } from '../../../common/hooks/useBadge';

export interface IVerticalProductCardProps {
    item: IProduct;
    product: IDomainProductItem; // TODO refactor and don't pass the whole product object
    productName: string;
    isSideScroll: boolean;
    categoryUrl: string;
    className?: string;
    gtmId?: string;
    crossSellGtmId?: string;
    containerClass?: string;
    addClass?: string;
    modifyClass?: string;
    sodiumWarning?: InspireCmsEntry<ISodiumWarningFields>;
    shouldShowSodiumWarning?: boolean;
    price: number;
    discountedPrice?: number;
    calories: number;
    calorieRange?: { min: string; max: string };
    isLoading?: boolean;
    isCombo?: boolean;
    isSaleable: boolean;
    isAvailable: boolean;
    isOrderAheadAvailable: boolean;
    isProductHasRequiredModifiers?: boolean;
    canProductBeModified?: boolean;
    productBadge?: string;
    bogoBadge?: string;
    onAddToBag: () => void;
    onModify: () => void;
    onCardLinkClick: () => void;
    shouldShowCaloriesAndPrice?: boolean;
    shouldShowControls?: boolean;
    shouldImageLoadEager?: boolean;
    showDiscountBadge?: boolean;
}

const VerticalProductCard: FC<IVerticalProductCardProps> = (props) => {
    const {
        item,
        product,
        productName,
        isSideScroll,
        categoryUrl,
        className,
        gtmId = 'productItem',
        crossSellGtmId,
        containerClass = '',
        addClass = '',
        modifyClass = '',
        sodiumWarning,
        price,
        discountedPrice,
        calories,
        calorieRange,
        isLoading = false,
        isCombo = false,
        isSaleable,
        isAvailable,
        isOrderAheadAvailable,
        isProductHasRequiredModifiers = false,
        canProductBeModified = false,
        shouldShowSodiumWarning = false,
        productBadge,
        bogoBadge,
        onAddToBag,
        onModify,
        onCardLinkClick,
        shouldShowCaloriesAndPrice = true,
        shouldShowControls = true,
        shouldImageLoadEager = false,
        showDiscountBadge = false,
    } = props;

    const productId = product?.id;
    const { getBadge } = useBadge();
    const badgeDetails = getBadge(ItemType.PRODUCTS, item.fields.productId);
    const verticalProductCardClassName = classnames(containerClass, styles.verticalProductCard, className, {
        [styles.badgeVerticalProductCard]: badgeDetails?.shouldDisplayBadge,
        [styles.verticalProductCardGrid]: !isSideScroll,
        [styles.verticalProductCardSideScroll]: isSideScroll,
    });

    const crossSellOrRegGtmId = crossSellGtmId || gtmId;

    return (
        <div data-gtm-id={crossSellOrRegGtmId} className={verticalProductCardClassName}>
            {productBadge && <InspireBadge className="productBadge" value={productBadge} />}
            {bogoBadge && <InspireBadge className="bogoBadge" value={bogoBadge} />}
            <ContentfulBadge content={badgeDetails} />
            {showDiscountBadge && discountedPrice !== undefined && (
                <InspireBadge className="productBadge" theme="dark" value="DISCOUNT" />
            )}
            <InspireLink link={item} currentCategoryUrl={categoryUrl} onClick={onCardLinkClick}>
                <VerticalProductCardContent
                    item={item}
                    sodiumWarning={sodiumWarning}
                    price={price}
                    discountedPrice={discountedPrice}
                    calories={calories}
                    calorieRange={calorieRange}
                    shouldShowSodiumWarning={shouldShowSodiumWarning}
                    shouldShowCaloriesAndPrice={shouldShowCaloriesAndPrice}
                    shouldImageLoadEager={shouldImageLoadEager}
                    crossSellOrRegGtmId={crossSellOrRegGtmId}
                    productName={productName}
                    isLoading={isLoading}
                    isSaleable={isSaleable}
                    isAvailable={isAvailable}
                    isOrderAheadAvailable={isOrderAheadAvailable}
                />
            </InspireLink>
            {shouldShowControls && (
                <ProductItemControls
                    isOrderAheadAvailable={isOrderAheadAvailable}
                    product={item}
                    isSaleable={isSaleable}
                    isAvailable={isAvailable}
                    isCombo={isCombo}
                    onClick={onAddToBag}
                    actionsWrapperGtmId={crossSellOrRegGtmId}
                    gtmId={crossSellGtmId || `${productId}|${productName}`}
                    onModify={onModify}
                    addClass={addClass}
                    modifyClass={modifyClass}
                    isLoading={isLoading}
                    isProductHasRequiredModifier={isProductHasRequiredModifiers}
                    canProductBeModified={canProductBeModified}
                    categoryUrl={categoryUrl}
                />
            )}
        </div>
    );
};

export default VerticalProductCard;
