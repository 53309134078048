import { IProduct } from '../../@generated/@types/contentful';
import { ILegalWarningModel, ItemModel, ItemModifierModel } from '../../@generated/webExpApi';
import { IProducts } from '../../redux/hooks/domainMenu';
import { getContentfulProductIdsByFields } from './getContentfulProductIdsByFields';
import { getProductByIds } from './getProductByIds';
import { getModifierPrice } from '../../lib/domainProduct';
import { PDPTallyItemModifierGroup } from '../../redux/types';

export const getProductSodiumValue = (product: ItemModel): number => {
    return (
        product?.nutrition?.macroNutrients?.['Sodium (mg)']?.weight?.value ||
        product?.nutrition?.macroNutrients?.['Sodium']?.weight?.value ||
        0
    );
};

export const getModifierGroupsSodiumValue = (
    modifierGroups: PDPTallyItemModifierGroup[],
    products: IProducts
): number => {
    const modifiersSodiumValue = (modifierGroups || []).reduce((acc, group) => {
        const modifiersSodiumCount = group.modifiers.reduce((modifiersAcc, modifier) => {
            const modifierSodiumValue = getProductSodiumValue(products?.[modifier.productId]);
            const quantity = modifier?.quantity || 0;
            return modifiersAcc + modifierSodiumValue * quantity;
        }, 0);

        return acc + modifiersSodiumCount;
    }, 0);

    return modifiersSodiumValue;
};

export const isProductHasSodiumWarning = (sodiumValue: number, sodiumLegalWarning: ILegalWarningModel): boolean => {
    return sodiumValue > sodiumLegalWarning?.additionalProperties?.threshold;
};

export const isProductsHasSodiumWarning = (
    products: IProduct[],
    domainProducts: IProducts,
    sodiumLegalWarning: ILegalWarningModel
): boolean => {
    return products?.some((product) => {
        const domainProduct = getProductByIds(getContentfulProductIdsByFields(product.fields), domainProducts);
        if (domainProduct) {
            const modifiers = getModifierGroupsFromProductId(domainProduct.id, domainProducts);
            const modifierSodium = getModifierGroupsSodiumValue(modifiers, domainProducts);
            const productSodiumValue = getProductSodiumValue(domainProduct);

            return isProductHasSodiumWarning(productSodiumValue + modifierSodium, sodiumLegalWarning);
        }
        return false;
    });
};

export const getModifierGroupsFromProductId = (productId: string, products: IProducts): PDPTallyItemModifierGroup[] => {
    const itemModifierGroups = products[productId]?.itemModifierGroups;
    const hasDefaultQuantity = (item: ItemModifierModel) => item.defaultQuantity > 0;
    const modifiers = itemModifierGroups?.map((itemModifierGroup) => {
        return {
            productId: itemModifierGroup?.productGroupId,
            metadata: itemModifierGroup?.metadata,
            modifiers: Object.values(itemModifierGroup.itemModifiers || [])
                ?.filter(hasDefaultQuantity)
                .map((modifier) => {
                    const productItemModifier = products[modifier?.itemId];
                    const price = getModifierPrice(modifier, productItemModifier);

                    return {
                        productId: modifier?.itemId,
                        price,
                        quantity: modifier?.defaultQuantity,
                    };
                }),
        };
    });

    return modifiers;
};
