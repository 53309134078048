import { ILegalWarningModel, LegalWarningTypeEnumModel } from '../../@generated/webExpApi';
import { useOrderLocation } from '../../redux/hooks';

export const useSodiumLegalWarning = (): ILegalWarningModel => {
    const { currentLocation } = useOrderLocation();
    const sodiumLegalWarning = currentLocation?.legalWarnings?.find(
        (legalWarning) => legalWarning.legalWarningType === LegalWarningTypeEnumModel.Sodium
    );

    return sodiumLegalWarning;
};
