import React, { FC } from 'react';
import classnames from 'classnames';

import { IProductFields, ISodiumWarningFields } from '../../../@generated/@types/contentful';
import { InspireCmsEntry } from '../../../common/types';
import ContentfulImage from '../ContentfulImage';
import { ProductItemPriceAndCalories } from '../productItemPriceAndCalories';
import TextWithTrademark from '../textWithTrademark';
import {
    SHOW_SODIUM_WARNING,
    IMAGE_ASPECT_RATIO_WIDTH,
    IMAGE_ASPECT_RATIO_HEIGHT,
    IMAGE_MAX_WIDTH,
    SHOW_UNAVAILABLE_WARNING,
} from './constants';
import styles from './verticalProductCard.module.css';

export interface IVerticalProductCardContentProps {
    item: InspireCmsEntry<IProductFields>;
    productName: string;
    sodiumWarning?: InspireCmsEntry<ISodiumWarningFields>;
    shouldShowSodiumWarning?: boolean;
    price?: number;
    discountedPrice?: number;
    calories: number;
    calorieRange?: { min: string; max: string };
    isLoading?: boolean;
    isSaleable?: boolean;
    isOrderAheadAvailable: boolean;
    shouldShowCaloriesAndPrice?: boolean;
    shouldImageLoadEager?: boolean;
    crossSellOrRegGtmId?: string;
    caloriesClassName?: string;
    isAvailable?: boolean;
}

export const VerticalProductCardContent: FC<IVerticalProductCardContentProps> = (props) => {
    const {
        item,
        sodiumWarning,
        price,
        discountedPrice,
        calories,
        calorieRange,
        shouldShowSodiumWarning,
        shouldShowCaloriesAndPrice,
        shouldImageLoadEager,
        crossSellOrRegGtmId,
        productName,
        isLoading,
        isSaleable,
        isOrderAheadAvailable,
        caloriesClassName,
        isAvailable,
    } = props;

    const { fields } = item;
    const { image } = fields;

    return (
        <div>
            <ContentfulImage
                asset={image}
                maxWidth={IMAGE_MAX_WIDTH}
                width={IMAGE_ASPECT_RATIO_WIDTH}
                height={IMAGE_ASPECT_RATIO_HEIGHT}
                className={styles.itemImage}
                gtmId={crossSellOrRegGtmId}
                {...(shouldImageLoadEager && { loading: 'eager' })}
            />
            <div data-gtm-id={crossSellOrRegGtmId} className={styles.itemDescription}>
                <TextWithTrademark
                    tag="span"
                    text={productName}
                    title={productName}
                    className={classnames(styles.itemName, 'truncate-at-3', 't-subheader-small')}
                    data-gtm-id={crossSellOrRegGtmId}
                    dataTestId="product-name"
                    afterContent={
                        shouldShowSodiumWarning &&
                        SHOW_SODIUM_WARNING && (
                            <img
                                key="sodium"
                                className={styles.sodiumWarningIcon}
                                src={sodiumWarning.fields.icon.fields.icon.fields.file.url}
                                alt="Sodium warning label"
                            />
                        )
                    }
                />
                {shouldShowCaloriesAndPrice && (
                    <ProductItemPriceAndCalories
                        isLoading={isLoading}
                        gtmId={crossSellOrRegGtmId}
                        price={isSaleable && isOrderAheadAvailable ? price : null}
                        offerPrice={discountedPrice}
                        calories={calories}
                        calorieRange={calorieRange}
                        className={classnames('t-paragraph-hint', styles.caloriesAndPrice, caloriesClassName)}
                    />
                )}
            </div>
            {SHOW_UNAVAILABLE_WARNING && !isAvailable && (
                <div className={classnames('t-paragraph-hint', styles.tempUnavailable)}>Temporarily Unavailable</div>
            )}
        </div>
    );
};
