import { useBag } from '../../redux/hooks';
import { useProducts } from '../../redux/hooks/domainMenu';
import { useDisplayRelatedGroups } from '../../redux/hooks/pdp';
import { PDPTallyItem } from '../../redux/pdp';
import { getModifierGroupsSodiumValue, getProductSodiumValue } from '../helpers/sodiumWarningHelper';
import { useCheckIsCondiment } from './useCheckIsCondiment';
import { useSodiumLegalWarning } from './useSodiumLegalWarning';

export const useTallyItemHasSodiumWarning = (pdpTallyItem: PDPTallyItem): boolean => {
    const products = useProducts();
    const { bagEntries } = useBag();
    const sodiumLegalWarning = useSodiumLegalWarning();

    const productSodium = getProductSodiumValue(products?.[pdpTallyItem.productId]);

    const modifiersSodium = getModifierGroupsSodiumValue(pdpTallyItem.modifierGroups, products);

    const condumentsGroup = useDisplayRelatedGroups();

    const checkIsCondiment = useCheckIsCondiment();

    const bagCondiments = (bagEntries || []).filter(({ productId }) => checkIsCondiment(productId));

    const uniqueBagCondiments =
        (condumentsGroup?.[0]?.modifiers &&
            bagCondiments.filter(
                ({ productId }) =>
                    !condumentsGroup[0].modifiers.some(
                        (modifier) => modifier.displayProductDetails.productId === productId
                    )
            )) ||
        [];

    const relatedItemGroupsWithoutCondimentsFromBag = pdpTallyItem.relatedItemGroups?.[0]
        ? [
              {
                  ...pdpTallyItem.relatedItemGroups[0],
                  modifiers: pdpTallyItem.relatedItemGroups[0].modifiers.filter(
                      (modifier) => !uniqueBagCondiments.some(({ productId }) => productId === modifier.productId)
                  ),
              },
          ]
        : undefined;

    const condimentsSodium = getModifierGroupsSodiumValue(relatedItemGroupsWithoutCondimentsFromBag, products);

    const childItemsSodium = (pdpTallyItem.childItems || []).reduce((acc, childItem) => {
        const childSodium = getProductSodiumValue(products?.[childItem.productId]);
        const childModifiersSodium =
            getModifierGroupsSodiumValue(pdpTallyItem.modifierGroups, products) ||
            getModifierGroupsSodiumValue(childItem.modifierGroups, products);
        return acc + childSodium + childModifiersSodium;
    }, 0);

    const totalSodium = productSodium + modifiersSodium + childItemsSodium + condimentsSodium;

    return totalSodium >= sodiumLegalWarning?.additionalProperties?.threshold;
};
